import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import textContent from '../assets/textContent'

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    textAlign: 'left',
    padding: 0,
  },
  media: {
    height: 200,
  },
});

export default function AboutCard({ info, pic }) {
  console.log(info)
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <img src={pic} alt="profile"></img>
          <Typography gutterBottom variant="h5" component="h2">
            <strong>John W. Olivo, Jr</strong>
          </Typography>
          <Typography variant="body2" color="textSecondary" component={'div'}>
            {textContent.about.map(item => {
              return <p>{item}</p>
            })}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card >
  );
}
