import React from 'react';

import CopyrightIcon from '@material-ui/icons/Copyright';
import { Box, Typography } from '@material-ui/core/';

const footer = {
  backgroundColor: "white",
  justif: "center",
  padding: "20px",
  left: "0",
  bottom: "0",
  height: "30px",
  width: "100%",
}

const phantom = {
  display: 'block',
  padding: '5px',
  height: '10px',
  width: '100%',
}

const Footer = () => {
  return (
    <div>
      <div style={phantom} />
      <div style={footer}>
        <Typography variant="body2" color="textSecondary" component={'div'}>
          <Box textAlign="center" m={1}>
            ©2020 Olivo IP Law Group, PC
          </Box>
        </Typography>
      </div>
    </div>
  )
}

export default Footer