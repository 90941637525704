const textContent = {
  home:
    [{
      title: 'Intellectual Property Monetization',
      description: 'We continue to distinguish ourselves as one of the pre-eminent intellectual property groups in the country and maintain strong relationships with many Fortune 500 companies.  We have a proven track record of actively and successfully pursuing maximum monetization opportunities for our clients through careful and comprehensive litigation practices.',
      imgSrc: '../assets/litigation.jpg'
    },
    {
      title: 'Prosecution',
      description: 'Based on our traditional roots in the intellectual property legal profession, we continue to assist our clients through the process of obtaining patent, trademark and copyright protection. Often times, we will also support its clients’ prosecution efforts with a view towards long term enforcement for either monetization or defensive purposes.',
      imgSrc: '../assets/prosecution.jpg'
    },
    {
      title: 'Licensing and Advice',
      description: 'Through our affiliate Olivo IP Group, Inc. (OIPG), we actively pursue novel and innovative approaches to intellectual property creation, management, monetization and commercialization. Recognizing that no two intellectual property portfolios are the same, we utilize our extensive network of industry partners in order to work together to offer unique and comprehensive plans that greatly enhance returns on investment.  We provide consultation on many IP issues, including but not limited to infringement, cryptocurrency, trademarks, etc.',
      imgSrc: '../assets/licensing.jpg'
    }
    ],
  about: [`John (“Jack”) W. Olivo, Jr. was born in Philadelphia and raised in New Jersey.  He received his undergraduate degree from Lafayette College in Pennsylvania and his law degree from New York Law School.  Jack has an extensive background in both electrical engineering and intellectual property law.  Prior to attending New York Law School in 1986, Jack was employed as an electrical engineer with RCA Astro-Electronics in Princeton, New Jersey engaged in the design of direct broadcast satellite communications and defense satellites.  Later in 1986, Jack became one of the principal inventors of the “V-Chip”, which regulates the amount of violence transmitted by electronic media such as televisions, DVD players, video games and so forth.  He bridged the gap between electrical engineering and the legal field when he solved a legal problem with a technological solution.`,
    `Jack was associated with the law firm of Pennie & Edmonds, based in New York City.  While at Pennie & Edmonds, Jack represented numerous leading companies in the travel, finance, retail and technology sectors, and concentrated on the defense of patent enforcement claims involving complex technology.  In 1992, he and his partner founded Ward & Olivo, which had offices in New York City, New Jersey, Texas, and Hartford, Connecticut.  Over the span of two decades, the firm enjoyed many successes and became somewhat unique and prominent balancing two fronts: the defense of nationally significant patent cases and the enforcement of patents for plaintiffs on a contingent fee basis. Notably, several of the most influential and substantial law firms in the world engaged Ward & Olivo for its expertise in the fields of telecommunications and software, with Jack leading in those efforts. Ward & Olivo represented every major airline, numerous banking, retail and scientific companies, until the firm was strategically dissolved in 2011.`, `Jack moved to California from New Jersey in 2010, and in 2011, Jack founded the Olivo IP Law Group, PC and the Olivo IP Group to continue his legal practice and to offer his advisory expertise to prospective California clients.`,
    `Jack holds a number of professional memberships including the Institute of Electrical and Electronic Engineers, Society of Automotive Engineers, Society of Motion Picture and Television Engineers, American Bar Association (sections on intellectual property and communications law), New York State Bar Association (sections on intellectual property and entertainment law), New Jersey State Bar Association (section on intellectual property law), Association of Trial Lawyers of America, New Jersey Patent Law Association (chairman, committee on ethics, grievances and the unauthorized practice of law) and the New York Intellectual Property Law Association (chairman, design protection).`,
    `Jack is admitted to practice law in the state courts of New York and New Jersey, and the United States District Courts for the Southern and Eastern Districts of New York, the District Court of New Jersey, the Court of Appeals for the Federal Circuit and the United States Supreme Court.`,
    `On its own and through national strategic lawyer affiliations, Olivo IP Law Group has the capability to coordinate and conduct legal services internationally.`],
  news: [
    {
      title: '2012 Jack Olivo Announces the formation of Olivo Patent Group, LLC (OPG)',
      description1: ['Olivo Patent Group, LLC (OPG), formed in 2011, is a next generation Intellectual Property “IP” creation, management, monetization and commercialization consulting firm. Building on its IP enforcement expertise, OPG has the resources and expertise to maximize the value of IP portfolios through licensing, joint ventures, development, and IP based enterprise establishment.', 'OPG is building on its core expertise, capabilities and relationships in order to provide the full range of services required in today’s IP marketplace to create, identify, protect and maximize the harvest potential of IP value. To better service its clients and partners and to better communicate the OPG advantage in the marketplace, the group has developed 5 key areas of service delivery and offering development:'],
      bullets: ['IP Portfolio Management', 'IP Portfolio Strategic Commercialization', 'IP Portfolio Strategic Monetization', 'IP Portfolio Acquisition and Aggregation', 'Other IP Services'],
      description2: ['OPG is presently collaborating with various clients and financial partners who share the commitment and vision to leverage IP in both traditional and non-traditional ways for greatly enhanced returns on investment. OPG’s business model provides that a portion of retained earnings and additional investment proceeds are used to acquire rights to additional IP, monetize the portfolios in its control and fund the investment participation in new ventures and commercialization surrounding the IP if there is a market need for the product or technology.', 'OPG is headquartered in Beverly Hills with additional offices in New York and New Jersey.']
    },
    {
      title: '2011 Jack Olivo announces the formation of Olivo Law Group, PC',
      description1: ['John (“Jack”) W. Olivo, Jr. formed Olivo Law Group, PC (OLG) to continue his legal practice of enforcing intellectual property claims on a contingent fee basis. OLG currently maintains offices in New York City, and Chatham, NJ, but coordinates and conducts legal services globally.']
    }],
}

export default textContent;