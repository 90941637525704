import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ContactCard from '../components/ContactCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 'auto',
    width: 'auto',
  },
  control: {
    padding: theme.spacing(4),
  },
}));


const Contact = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={3}>
          <Grid item>
            <ContactCard />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Contact;