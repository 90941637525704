import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab, Box } from '@material-ui/core/';
import { Typography } from '@material-ui/core';

import Home from '../containers/Home';
import About from '../containers/About';
// import News from '../containers/News.js';
import Contact from '../containers/Contact.js';

import Background from '../assets/bg.jpg'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundImage: `url(${Background})`
  },
});

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  chileren: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function Menu() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Home" {...a11yProps(0)} />
        <Tab label="About" {...a11yProps(1)} />
        {/* <Tab label="News" {...a11yProps(2)} /> */}
        <Tab label="Contact" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Home />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <About />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <News />
      </TabPanel> */}
      <TabPanel value={value} index={2}>
        <Contact />
      </TabPanel>
    </Paper>
  );
}