import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import textContent from '../assets/textContent';
import Card from '../components/Card';

import litigation from '../assets/litigation.jpg'
import licensing from '../assets/licensing.jpg'
import prosecution from '../assets/prosecution.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    margin: 5,
    paddingBottom: 20,
  },
  paper: {
    height: 'auto',
    width: 'auto',
  },
  control: {
    padding: theme.spacing(2),
  },
  bottomLine: {
    paddingTop: 25,
  }
}));

const photoArray = [litigation, prosecution, licensing]

const Home = () => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title} variant="h5" gutterBottom="true">Services and Expertise</Typography>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={3}>
            {textContent.home.map((value, idx) => (
              <Grid key={idx} item>
                <Paper className={classes.paper}>
                  <Card info={value} pic={photoArray[idx]} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.bottomLine}>
        <Box fontStyle="italic" m={1}></Box>
      </Typography>
    </>
  );
}

export default Home;