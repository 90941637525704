import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Twitter from '../assets/twitter-icon.png';

const useStyles = makeStyles({
  root: {
    maxWidth: 1000,
    textAlign: 'Center',
    padding: 0,
  },
  media: {
    height: 200,
  },
  title: {
  }
});

export default function ContactCard() {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.title} gutterBottom variant="h5" component="h2">
        Contact Us
      </Typography>

      <Typography variant="body2" color="textSecondary" component={'div'}>
        Olivo IP Law Group, P.C. (OIPLG) continues its successful patent, trademark and copyright practice.
      </Typography>
      <br />
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography variant="body4" color="textPrimary" component={'div'}>
              <br />
              <Typography variant="body1" color="textPrimary" component={'div'}><strong>OLIVO IP LAW GROUP, P.C.</strong></Typography>
              <p>JOHN W. OLIVO, JR.</p>
              <p>ATTORNEY AT LAW</p>
              <a href={`mailto:jackolivo@olivoipg.com`}>JACKOLIVO@OLIVOIPG.COM</a>
              <br />
              <p>TOLL FREE: 1-800-99-OLIVO</p>
              <p>FAX: (323) 843-9896</p>
              <br />
              <p>116 VILLAGE BLVD.</p>
              <p>SUITE 200</p>
              <p>PRINCETON, NJ 08450</p>
              <br />
              <p>5 PENN PLAZA</p>
              <p>23RD FLOOR</p>
              <p>NEW YORK, NY 10001</p>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card >
      <Card className={classes.root}>
        <CardActionArea>
          <CardContent>
            <Typography variant="body4" color="textPrimary" component={'div'}>
              <br />
              <Typography variant="body1" color="textPrimary" component={'div'}><strong>OLIVO IP GROUP, INC.</strong></Typography>
              <p>JOHN W. OLIVO, JR.</p>
              <a href={`mailto:jackolivo@olivoipg.com`}>JACKOLIVO@OLIVOIPG.COM</a>
              <br />
              <p>TOLL FREE: 1-800-99-OLIVO</p>
              <p>FAX: (323) 843-9896</p>
              <br />
              <p>9440 SANTA MONICA BLVD.</p>
              <p>SUITE 301</p>
              <p>BEVERLY HILLS, CA 90210</p>
              <br />
              <a href="https://twitter.com/jackolivojr" target="_blank"><img src={Twitter} width="30" /></a>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card >
    </>
  );
}
